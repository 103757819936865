import React from 'react'
import { css, StyleSheet } from 'aphrodite'

import Tile from './Tile'

import pic01 from '../../assets/images/design_and_space_planning.jpg'
import pic02 from '../../assets/images/production_drafting.jpg'
import pic03 from '../../assets/images/construction_documents.jpg'
import pic04 from '../../assets/images/construction_services.jpg'
import pic05 from '../../assets/images/project_financing.jpg'


const styles = StyleSheet.create({
  tiles: {
    display: 'flex',
    flexWrap: 'wrap',
    borderTop: 0
  }
})

function subtitleList(...items) {
  const ulStyles = {
    paddingLeft: 0,
    listStyle: 'none',
  };
  return (
    <ul style={ulStyles}>
      {items.map(item => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  )
}

function Tiles() {
  return (
    <div className={css(styles.tiles)}>
      <Tile
        bgImage={pic01}
        title='Design and Space Planning'
        subtitle={subtitleList('Concept Drawings', 'Full Design Drawings', 'Renderings')}
        bgColor='#6fc3df'
        wide={true}
      />
      <Tile
        bgImage={pic02}
        title='Production Drafting'
        subtitle={subtitleList('Architectural, Structural, Mechanical, Electrical, Plumbing, Civil', 'AutoCAD, REVIT, Sketchup and Chief Architect')}
        bgColor='#8d82c4'
        wide={false}
      />
      <Tile
        bgImage={pic03}
        title='Construction Documents'
        subtitle={subtitleList('Full, stamped and engineered documents Including Title 24', 'Permit-ready')}
        bgColor='#8ea9e8'
        wide={false}
      />
      <Tile
        bgImage={pic04}
        title='Construction Services'
        subtitle={subtitleList('Complete General Contracting Services (CA License #576412)', 'Ground-up new or remodels', 'Commercial or residential')}
        bgColor='#87c5a4'
        wide={true}
      />
      <Tile
        bgImage={pic05}
        title='Project Financing'
        subtitle={'Explore flexible financing solutions to support the vision and budget of your construction or renovation project. From initial planning to project completion, our financing options can help make your dream space a reality.'}
        bgColor='#6fc3df'
        wide={true}
        url={'https://pros.renofi.com/pros/ecco-design-build'}
      />

    </div>
  )
}

export default Tiles;
